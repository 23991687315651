import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable } from './styles';
import api from '../../../../services/api';
import { convertDate } from '../../../../utils/dateConvert';

interface IPropsEnterprise {
  key: React.Key;
  _id: string;
  license_number: string;
  created_at: string;
}

export function LicensesProcess({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const { main, enterprise_id, process_id }: any = useParams();
  let { enterprise_name }: any = useParams();
  enterprise_name = decodeURIComponent(enterprise_name);
  const [licenses, setLicenses] = useState<IPropsEnterprise[]>([]);
  const [loading, setLoading] = useState(true);

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  const columns: ColumnsType<IPropsEnterprise> = [
    {
      title: 'Número da licença',
      dataIndex: 'license_number',
      key: 'licensing_number',
    },
    {
      title: 'Data de criação',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => convertDate(text),
    },
    {
      title: 'Ação',
      dataIndex: '_id',
      key: '_id',
      render: (_, record) => (
        <Link
          to={`/enterprise/${encodeURIComponent(
            enterprise_name
          )}/${enterprise_id}/process/${process_id}/${main}/licencas/${window.btoa(
            record.license_number
          )}/condicionantes`}
        >
          Acessar
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const fetchLicenses = async () => {
      try {
        const enterpriseData = await api.get(`/conditional/process/${main}`);
        const data = enterpriseData.data.map((item: IPropsEnterprise) => ({
          ...item,
          key: item._id,
        }));
        setLicenses(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch licenses:', error);
      }
    };

    fetchLicenses();
  }, []);

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${enterprise_name}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${encodeURIComponent(
        enterprise_name
      )}/processos`,
    },
    {
      label: `${main}`,
    },
    {
      label: 'Licenças',
    },
  ];

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualize as licenças do projeto"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table pagination={false} columns={columns} dataSource={licenses} />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
