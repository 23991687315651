import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable } from './styled';
import api from '../../../../services/api';

interface IProposDataConditions {
  condition_code: string;
  key: string;
  _id: string;
}

export function Condition({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const {
    licensing_number,
    enterprise_id,
    process_id,
    main,
  }: any = useParams();
  let { enterprise_name }: any = useParams();
  enterprise_name = decodeURIComponent(enterprise_name);

  const [conditional, setconditional] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const AsyncApi = async () => {
      const conditionalData = await api.get(
        `/conditional/licensing/${licensing_number}`
      );

      const conditionalWithKey = conditionalData.data.map(
        (item: IProposDataConditions) => ({
          ...item,
          key: item.condition_code,
        })
      );
      setconditional(conditionalWithKey);
      setLoading(false);
    };
    AsyncApi();
  }, []);

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      key="back"
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  const breadcrumb = [
    { label: <HomeFilled />, link: '/dashboard' },
    { label: 'Empreendimentos', link: '/enterprise' },
    { label: `${enterprise_name}` },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${encodeURIComponent(
        enterprise_name
      )}/processos`,
    },
    { label: `${main}` },
    {
      label: 'Licenças',
      link: `/enterprise/${encodeURIComponent(
        enterprise_name
      )}/${enterprise_id}/process/${process_id}/${main}/licencas`,
    },
    { label: `${window.atob(licensing_number)}` },
    { label: `Condicionantes` },
  ];

  const columns: ColumnsType<IProposDataConditions> = [
    {
      title: 'Número',
      dataIndex: 'condition_number',
      key: '_id',
    },
    {
      title: 'Matriz',
      dataIndex: 'monitoring_matrix',
    },
    {
      title: 'Versão',
      dataIndex: 'version',
    },
    {
      title: 'Ação',
      key: 'action',
      render: (_, record) => (
        <Link
          to={{
            pathname: `/enterprise/${encodeURIComponent(
              enterprise_name
            )}/${enterprise_id}/process/${process_id}/${main}/licencas/${licensing_number}/condicionantes/${window.btoa(
              record.condition_code
            )}/ciclos/${record._id}`,
          }}
        >
          Acessar
        </Link>
      ),
    },
  ];

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualizar condicionantes"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table
            columns={columns}
            pagination={false}
            dataSource={conditional}
          />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
