import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { useHistory, Link, useParams } from 'react-router-dom';
import { Typography, Empty, Spin, Input } from 'antd';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { Container, Divider } from './styles';
import api from '../../../../services/api';
import { PointViewer } from '../../../../components/shered/PointViewer';
import { IstationProps } from '../../../../types/pointerView';
import { validAccess } from '../../../../hooks/roles';

const { Search } = Input;
const { Title } = Typography;

interface IFiltersCalls {
  text: string;
  value: string;
}

interface IFilters {
  monitoring_point: IFiltersCalls[];
}

export function StationList({ title }: TitleProps) {
  useDocumentTitle(title);
  let { enterprise_name }: any = useParams();
  const { enterprise_id }: any = useParams();
  enterprise_name = decodeURIComponent(enterprise_name);

  const history = useHistory();
  const [station, setStation] = useState<IstationProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<IFilters | null>(null);

  const [
    visibleCaracterizacaoDeponto,
    setVisibleCaracterizacaoDeponto,
  ] = useState(true);

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
    <>
      {visibleCaracterizacaoDeponto && (
        <Link
          to={`/enterprise/${enterprise_id}/${encodeURIComponent(
            enterprise_name
          )}/Station/caracterizacao`}
        >
          <Button size="large">Caracterização de Pontos</Button>
        </Link>
      )}
    </>,
  ];

  useEffect(() => {
    const AsyncApi = async () => {
      setVisibleCaracterizacaoDeponto(
        await validAccess('Caracterização de ponto')
      );

      const { data } = await api.get('/station');
      setStation(data);
      setLoading(false);
    };
    AsyncApi();
  }, []);

  const clickToSearch = (value: string) => {
    setFilters({
      monitoring_point: [{ text: value, value }],
    });
  };

  const filteredStation = station.filter(item =>
    item.name
      .toLowerCase()
      .includes(filters?.monitoring_point[0]?.value.toLowerCase() || '')
  );

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${enterprise_name}`,
    },
    {
      label: 'Pontos de monitoramento',
    },
  ];

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualize os pontos de monitoramento"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        <Divider>
          <Title level={5}>Informe o ponto de monitoramento</Title>
          <Search
            placeholder="Busque seu ponto de monitoramento"
            allowClear
            loading={loading}
            onSearch={clickToSearch}
            style={{ fontSize: '12px', width: '200px' }}
            enterButton
            type="primary"
          />
        </Divider>

        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <>
            {filteredStation.length ? (
              filteredStation.map((item, index) => (
                <PointViewer data={item} key={index} />
              ))
            ) : (
              <Empty description="Sem dados" />
            )}
          </>
        )}
      </Container>
    </LayoutAppCMS>
  );
}
