import React, { useEffect, useState } from 'react';
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  HomeFilled,
} from '@ant-design/icons';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable } from './styled';
import api from '../../../../services/api';

interface IPropsProcess {
  key: React.Key;
  _id: string;
  main: string;
}

const columns: ColumnsType<IPropsProcess> = [
  {
    title: 'Protocolo de Envio',
    dataIndex: 'protocol',
  },
  {
    title: 'Download',
    dataIndex: 'btn',
    align: 'center',
  },
];

export function ImportConditionList({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const token = localStorage.getItem('token');
  const location = useLocation();
  const { name_process }: any = location.state;
  let { name_enterprise }: any = location.state;
  name_enterprise = decodeURIComponent(name_enterprise);
  const { enterprise_id, process_id }: any = useParams();
  const [station, setStation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [condition, setCondition] = useState([]);

  const buttons = [
    <Button
      icon={<ArrowLeftOutlined />}
      size="large"
      onClick={() => history.goBack()}
    >
      Voltar
    </Button>,
    <Link
      to={{
        pathname: `/enterprise/${enterprise_id}/process/${process_id}/import-conditions`,
        state: {
          _name_enterprise: name_enterprise,
          _name_process: name_process,
        },
      }}
    >
      <Button type="primary" size="large">
        Nova Importação
      </Button>
    </Link>,
  ];

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${name_enterprise}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${encodeURIComponent(
        name_enterprise
      )}/processos`,
    },
    {
      label: `${name_process}`,
    },
    {
      label: 'Lista pontos e condicionantes',
    },
  ];

  function downloadFile(file: string) {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API}/file/download/others/${file}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.error('Ocorreu um erro ao baixar o arquivo:', error);
        toast.error('Erro ao baixar o arquivo.');
      });
  }

  const createButton = (item: any, fn: any) => {
    const btn: any = [];
    item.map((item: any) => {
      btn.push({
        ...item,
        btn: (
          <DownloadOutlined
            style={{ fontSize: 24 }}
            onClick={() => downloadFile(item.url)}
          />
        ),
      });
    });
    fn(btn);
  };

  useEffect(() => {
    const AsyncApi = async () => {
      const stationFiles = await api.get('/file/list/Station');
      const condictionFiles = await api.get('/file/list/Condition');
      createButton(stationFiles.data, setStation);
      createButton(condictionFiles.data, setCondition);
      setIsLoading(false);
    };
    AsyncApi();
  }, []);

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualize pontos e condicionantes"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        <h3>Arquivos de Pontos</h3>
        <Table
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={station}
          style={{ marginTop: 10 }}
        />
        <br />
        <h3>Arquivos de Condicionantes</h3>
        <Table
          loading={isLoading}
          pagination={false}
          columns={columns}
          dataSource={condition}
          style={{ marginTop: 10 }}
        />
      </ContentTable>
    </LayoutAppCMS>
  );
}
