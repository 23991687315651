import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { Alert, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import LayoutAppCMS from '../../../../../layouts/app';
import { PageDescription } from '../../../../../components/shered/HeaderCards';
import { Button } from '../../../../../components/shered/Button';
import { Container, ContentButton } from './styled';
import { FileUploader } from '../../../../../components/shered/FileUploader';
import { PointViewer } from '../../../../../components/shered/PointViewer';
import { StationContext } from '../../../../../providers/Upload-context';
import { ErrorObject } from '../../../../../types/errorObject';
import { IstationProps } from '../../../../../types/pointerView';
import api from '../../../../../services/api';
import { treatmentError } from '../../../../../hooks/erros';

interface IPropsFormStep1 {
  onNext: () => void;
  formData: any;
  setFormData: any;
  SetstationResponse: any;
}

const { Title } = Typography;
interface IStationPhysicalMedium {
  matrix: string;
  full_name: string;
}

export function FormStep1({
  onNext,
  formData,
  setFormData,
  SetstationResponse,
}: IPropsFormStep1) {
  const history = useHistory();
  const location = useLocation();
  const { name_process }: any = location.state;
  let { name_enterprise }: any = useLocation().state;
  name_enterprise = decodeURIComponent(name_enterprise);
  const { enterprise_id }: any = useParams();
  const [station, setStation] = useState<any>(null);
  const [stationList, setStationList] = useState<IstationProps[]>([]);
  const [exist, setExist] = useState<IStationPhysicalMedium[]>([]);
  const [error, setError] = useState<ErrorObject[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchStationExisting = async () => {
      const respStation = await api.get('/station');
      const stationSelect: IStationPhysicalMedium[] = respStation.data.map(
        (item: IStationPhysicalMedium) => ({
          full_name: item.full_name,
          matrix: item.matrix,
        })
      );
      setExist(stationSelect);
      setStationList(respStation.data);
      setFormData({
        ...formData,
        stationPhysicalMedium: stationSelect,
      });
    };
    fetchStationExisting();
  }, []);

  const onFileUploadSuccess = async (response: any) => {
    SetstationResponse(response.file.response);
    toast.success('Arquivo carregado com sucesso.');
    const stationSelectFile = response.file.response.stations
      .filter((item: IstationProps) => {
        const existPoint = exist.some(
          existItem =>
            existItem.full_name === item.full_name &&
            existItem.matrix === item.matrix
        );
        return !existPoint;
      })
      .map((item: IstationProps) => {
        return {
          full_name: item.full_name,
          matrix: item.matrix,
        };
      });
    setFormData({
      ...formData,
      stationPhysicalMedium: [...stationSelectFile, ...exist],
    });
  };

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${name_enterprise}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${encodeURIComponent(
        name_enterprise
      )}/processos`,
    },
    {
      label: `${name_process}`,
    },
    {
      label: 'Pontos e condicionantes',
    },
  ];

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  const onFileUploadError = (err: any) => {
    const error = treatmentError(err);
    toast.error(error);
  };

  const onFileUploadRemove = (fileName: string) => {
    console.log(fileName);
  };

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Adicione arquivo de ponto"
        title="Pontos e condicionantes"
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <Container>
        <StationContext.Provider
          value={{ station, setStation, error, setError, setLoading, loading }}
        >
          <FileUploader
            onFileRemove={onFileUploadRemove}
            onFileUploadSuccess={onFileUploadSuccess}
            onFileUploadError={onFileUploadError}
            uploadEndpoint={`${process.env.REACT_APP_URL_API}/file/stationValidate`}
            uploadMessage="Envie os arquivos"
            uploadHint="Selecione o arquivo com os dados cadastrais dos pontos de monitoramento"
            isConvertUTMtoWGS
          />
          {station !== null && (
            <>
              <Title style={{ marginTop: '2rem' }} level={3}>
                Novos Pontos:
              </Title>
              {station.stations.map((item: any, id: number) => (
                <React.Fragment key={id}>
                  {!item.is_new && (
                    <Alert
                      message="O ponto de monitoramento já foi cadastrado anteriormente, precisa ser excluído do arquivo."
                      type="error"
                      closable
                    />
                  )}
                  <PointViewer
                    styleProp={{
                      background: item.is_new ?? '#c76969',
                    }}
                    data={item}
                    key={item.id}
                  />
                </React.Fragment>
              ))}
              {stationList.length > 0 && (
                <>
                  <Title style={{ marginTop: '2rem' }} level={3}>
                    Pontos já cadastrados:{' '}
                  </Title>
                  {stationList.map((item: any) => (
                    <PointViewer data={item} key={item.id} />
                  ))}
                </>
              )}
            </>
          )}
        </StationContext.Provider>
        <ContentButton>
          <Button
            size="large"
            onClick={onNext}
            type="primary"
            disabled={
              (error && error?.length > 0) ||
              station?.stations.some((item: any) => !item.is_new)
            }
          >
            Avançar
          </Button>
        </ContentButton>
      </Container>
    </LayoutAppCMS>
  );
}
