import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ArrowLeftOutlined,
  HomeFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { TitleProps } from 'antd/es/typography/Title';
import Highlighter from 'react-highlight-words';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { validAccess } from '../../../../hooks/roles';
import api from '../../../../services/api';
import { ContentTable, FilterContainer, SpaceButton } from './styles';
import { Button } from '../../../../components/shered/Button';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import LayoutAppCMS from '../../../../layouts/app';
import { Input } from '../../../../components/shered/Input';

interface IPropsProcess {
  key: React.Key;
  _id: string;
  main: string;
}

const buttons = [
  <Link to="/enterprise">
    <Button icon={<ArrowLeftOutlined />} size="large">
      Voltar
    </Button>
  </Link>,
];

export function ProcessList({ title }: TitleProps) {
  let { enterprise_name }: any = useParams();
  const { enterprise_id }: any = useParams();
  enterprise_name = decodeURIComponent(enterprise_name);

  useDocumentTitle(title);
  const [enterprise, setEnterprise] = useState<IPropsProcess[]>([]);
  const [loading, setLoading] = useState(true);
  const [
    visibleCriarPontosCondicionantes,
    setVisibleCriarPontosCondicionantes,
  ] = useState(false);
  const [
    visibleImportarPontosCondicionantes,
    setVisibleImportarPontosCondicionantes,
  ] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<any>(null);

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('');
    setSearchedColumn('');
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <FilterContainer>
        <Input
          ref={searchInput}
          placeholder={`Busque ${'processos'}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Limpar
          </Button>
        </Space>
      </FilterContainer>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible && searchInput.current !== null) {
        searchInput.current.select();
      }
    },
    render: (text: any) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  const { id }: any = useParams();

  const columns: ColumnsType<IPropsProcess> = [
    {
      title: 'Processo',
      dataIndex: 'main',
      ...getColumnSearchProps('main'),
    },
  ];
  useEffect(() => {
    const AsyncApi = async () => {
      setVisibleCriarPontosCondicionantes(
        await validAccess('Criar Pontos e Condicionantes')
      );
      setVisibleImportarPontosCondicionantes(
        await validAccess('Importar pontos e condicionantes')
      );

      await api.post('/process/byCuca');
      const enterpriseData = await api.get('/process');
      const data: IPropsProcess[] = [];
      enterpriseData.data.map((item: IPropsProcess, id: number) => {
        const dataItem = {
          ...item,
          key: id,
        };
        data.push(dataItem);
      });
      setEnterprise(data);
      setLoading(false);
    };
    AsyncApi();
  }, []);

  const breadcrumb = [
    { label: <HomeFilled />, link: '/dashboard' },
    { label: 'Empreendimentos', link: '/enterprise' },
    { label: `${enterprise_name}` },
    { label: 'Processos' },
  ];

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Monitore os processos desse empreendimento"
        title={title || 'Processos'}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table
            pagination={false}
            columns={columns}
            expandable={{
              expandedRowRender: record => (
                <SpaceButton>
                  <Link
                    to={{
                      pathname: `/enterprise/${enterprise_id}/process/${record._id}/usuarios`,
                      state: {
                        name_enterprise: enterprise_name,
                        name_process: record.main,
                      },
                    }}
                  >
                    <Button type="primary">Usuários vinculados</Button>
                  </Link>
                  {visibleCriarPontosCondicionantes && (
                    <Link
                      to={{
                        pathname: `/enterprise/${record._id}/process/${record.main}/Station/create`,
                        state: {
                          name_enterprise: enterprise_name,
                          name_process: record.main,
                        },
                      }}
                    >
                      <Button type="primary">
                        Criar Pontos e Condicionantes
                      </Button>
                    </Link>
                  )}

                  <Link
                    to={`/enterprise/${encodeURIComponent(
                      enterprise_name
                    )}/${enterprise_id}/process/${record._id}/${window.btoa(
                      record.main
                    )}/licencas`}
                  >
                    <Button type="primary">Licenças</Button>
                  </Link>
                  {visibleImportarPontosCondicionantes && (
                    <Link
                      to={{
                        pathname: `/enterprise/${id}/process/${record._id}`,
                        state: {
                          name_enterprise: enterprise_name,
                          name_process: record.main,
                        },
                      }}
                    >
                      <Button type="primary">
                        Importar pontos e condicionantes
                      </Button>
                    </Link>
                  )}
                </SpaceButton>
              ),
            }}
            dataSource={enterprise}
          />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
