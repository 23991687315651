import React, { useEffect, useState } from 'react';
import { ArrowLeftOutlined, HomeFilled } from '@ant-design/icons';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { TitleProps } from '../../../../global/interfaces/TitleProps';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import LayoutAppCMS from '../../../../layouts/app';
import { PageDescription } from '../../../../components/shered/HeaderCards';
import { Button } from '../../../../components/shered/Button';
import { ContentTable } from './styles';
import api from '../../../../services/api';

interface IPropsUsers {
  key: string;
  userType: string;
  user_name: string;
}

const columns: ColumnsType<IPropsUsers> = [
  {
    title: 'Nome do usuário',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Tipo de usuário',
    dataIndex: 'role',
    key: 'role',
  },
];

export function UsersLinked({ title }: TitleProps) {
  useDocumentTitle(title);
  const history = useHistory();
  const { name_process }: any = useLocation().state;
  let { name_enterprise }: any = useLocation().state;
  name_enterprise = decodeURIComponent(name_enterprise);
  const { enterprise_id, process_id }: any = useParams();
  const [user, setUser] = useState<IPropsUsers[]>([]);
  const [loading, setLoading] = useState(true);

  const breadcrumb = [
    {
      label: <HomeFilled />,
      link: '/dashboard',
    },
    {
      label: 'Empreendimentos',
      link: '/enterprise',
    },
    {
      label: `${name_enterprise}`,
    },
    {
      label: 'Processos',
      link: `/enterprise/${enterprise_id}/${encodeURIComponent(
        name_enterprise
      )}/processos`,
    },
    {
      label: `${name_process}`,
    },
    {
      label: 'Usuários vinculados',
    },
  ];

  const buttons = [
    <Button
      onClick={() => history.goBack()}
      icon={<ArrowLeftOutlined />}
      size="large"
    >
      Voltar
    </Button>,
  ];

  useEffect(() => {
    const AsyncApi = async () => {
      const enterpriseData = await api.get(`/process/listUsers/${process_id}`);
      setUser(enterpriseData.data);
      setLoading(false);
    };
    AsyncApi();
  }, []);

  return (
    <LayoutAppCMS>
      <PageDescription
        description="Visualizar usuários vinculados"
        title={title}
        breadcrumb={breadcrumb}
        buttons={buttons}
      />
      <ContentTable>
        {loading ? (
          <Spin tip="Carregando..." size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Table pagination={false} dataSource={user} columns={columns} />
        )}
      </ContentTable>
    </LayoutAppCMS>
  );
}
